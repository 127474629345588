import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { RecipeTypes } from './types';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';
import HomepageRecipeCardCaption from './HomepageRecipeCardCaption';
import HomepageRecipeCardDraft from './HomepageRecipeCardDraft';
import lockIcon from 'images/lock-icon-white.svg';
import cx from 'classnames';

const HomepageRecipeCard = ({
  module,
  type,
  position,
  link,
  name,
  image,
  author,
  isVideo,
  isDraft = false,
  user = {},
  cover,
  twoLineTitle = false,
  isUserPage = false,
  isUserPageEdit = false,
  description = null
}) => {
  return (
    <a
      href={link || '#'}
      className={cx(styles.homepageRecipeCard, {
        [styles.twoLineTitleHeight]: twoLineTitle,
        [styles.isUserPage]: isUserPage,
        [styles.isUserPageEdit]: isUserPageEdit
      })}
      data-track-id="homepage"
      data-module={module}
      data-position={position}
      data-type={type}
      data-title={name}
    >
      <picture
        className={cx(styles.homepageRecipeCardImage, {
          [styles.isUserPageEditImage]: isUserPageEdit
        })}
      >
        <Image
          className={cx(styles.homepageRecipeCardImageSource, {
            [styles.homepageRecipeCardImageSourceDraft]: isDraft
          })}
          alt={name}
          src={imaginaryUrl(image || cover?.url || '', { width: 600 })}
        />
        {isVideo && (
          <i
            className={`icon-play-circle-regular ${styles.homepageRecipeCardImageVideoIcon}`}
          />
        )}
        {isDraft && (
          <img className={styles.draftLockIcon} src={lockIcon} alt="未發布" />
        )}
      </picture>
      {isDraft ? (
        <HomepageRecipeCardDraft name={name} description={description} />
      ) : (
        <HomepageRecipeCardCaption
          name={name}
          author={author || user?.nickname || ''}
          twoLineTitle={twoLineTitle}
          isUserPageEdit={isUserPageEdit}
        />
      )}
    </a>
  );
};

HomepageRecipeCard.propTypes = {
  position: PropTypes.number.isRequired,
  module: PropTypes.string.isRequired,
  isDraft: PropTypes.bool,
  ...RecipeTypes
};

export default HomepageRecipeCard;
