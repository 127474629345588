import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import { PostSourceTypes } from './types';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';

const IMAGE_WHITE_LIST = [
  'tokyo-kitchen.icook.tw.s3.amazonaws.com',
  'icook.network',
  'icook.tw',
  'www.roomie.tw'
];

const isImageWhiteList = (image) => {
  return IMAGE_WHITE_LIST.some((host) => image.includes(host));
};

const HomepageBlog = ({ title, link, type, content }) => {
  const makeImageUrl = (image) => {
    if (!image) return '';
    if (!isImageWhiteList(image)) return image;
    return imaginaryUrl(image, { width: 400 });
  };

  return (
    <HomepageSection title={title} link={link} type={type}>
      {content.map(({ id, link, title: postTitle, image }, index) => (
        <a
          href={link}
          key={id}
          className={styles.homepageBlogItem}
          data-track-id="homepage"
          data-module={title}
          data-position={index + 1}
          data-type={type}
        >
          <figure className={styles.homepageBlogItemImage}>
            <Image
              className={styles.homepageBlogItemImageSource}
              src={makeImageUrl(image)}
              alt={postTitle}
              width={80}
            />
            <figcaption className={styles.homepageBlogItemImageCaption}>
              {postTitle}
            </figcaption>
          </figure>
        </a>
      ))}
    </HomepageSection>
  );
};

HomepageBlog.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(PostSourceTypes).isRequired
};

export default HomepageBlog;
