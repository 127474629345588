import PropTypes from 'prop-types';
import styles from './style.module.scss';
import { PostTypes } from './types';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';

const HomepagePostCard = ({ module, position, type, link, title, image }) => {
  return (
    <a
      href={link}
      className={styles.homepagePostCard}
      data-track-id="homepage"
      data-module={module}
      data-position={position}
      data-type={type}
    >
      <picture className={styles.homepagePostCardImage}>
        <Image
          className={styles.homepagePostCardImageSource}
          alt={title}
          src={imaginaryUrl(image, { width: 600 })}
        />
      </picture>
      <h4 className={styles.homepagePostCardCaption}>{title}</h4>
    </a>
  );
};

HomepagePostCard.propTypes = {
  type: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  module: PropTypes.string.isRequired,
  ...PostTypes
};

export default HomepagePostCard;
