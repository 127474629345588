import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import Button from '@polydice/ui/dist/Button';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';

const HomepageMarketProducts = ({ title, link, type, content }) => {
  return (
    <HomepageSection title={title} link={link} type={type} isMobileScroll>
      {content.map(({ id, link, name, subtitle, image }, index) => (
        <a
          href={link}
          key={id}
          className={styles.homepageMarketProductsItem}
          data-track-id="homepage"
          data-module={title}
          data-position={index + 1}
          data-title={name}
          data-type={type}
        >
          <div className="ratio-container ratio-container-16-9">
            <Image
              className={styles.homepageMarketProductsItemImageSource}
              src={imaginaryUrl(image, { width: 400 })}
              alt={title}
            />
          </div>
          <div className={styles.homepageMarketProductsItemImageCaption}>
            <h3
              id={name}
              className={styles.homepageMarketProductsItemImageCaptionTitle}
            >
              {name}
            </h3>
            <div
              className={
                styles.homepageMarketProductsItemImageCaptionDescription
              }
              aria-describedby={name}
            >
              <span
                className={
                  styles.homepageMarketProductsItemImageCaptionDescriptionSubtitle
                }
              >
                {subtitle}
              </span>
              <Button
                className={
                  styles.homepageMarketProductsItemImageCaptionDescriptionButton
                }
                styleType="outlined"
                color="primary"
                size="xs"
                key={id}
              >
                選購
              </Button>
            </div>
          </div>
        </a>
      ))}
    </HomepageSection>
  );
};

HomepageMarketProducts.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default HomepageMarketProducts;
