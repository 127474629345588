import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import DishTagCard from '../Dish/Components/DishTagCard';
import DishAddCard from '../Dish/Components/DishAddCard';

const HomepageDish = ({ hastagList, currentUser, needAddCard = false }) => {
  const showList = hastagList?.slice(0, 6) || [];

  return (
    <HomepageSection
      title="大家一起做"
      link="/dishes"
      subTitle="近期熱門 #標籤"
      isMobileScroll
      isNew
    >
      <ul className={styles.homepageDish}>
        {showList?.map(
          ({ tag_id, tag_name, tag_count_last30, dish }, index) => (
            <DishTagCard
              key={index}
              id={tag_id}
              title={dish?.description || ''}
              image={dish?.photos.square.url || ''}
              tag={tag_name}
              link={`/dishes/${dish?.id || ''}`}
              type="homapge-dish"
              dishesCount={tag_count_last30 || 0}
              wider
              {...{ index }}
            />
          )
        )}
        {needAddCard && <DishAddCard {...currentUser} />}
      </ul>
    </HomepageSection>
  );
};

export default HomepageDish;
