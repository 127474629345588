import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import Image, { imaginaryUrl } from '@polydice/icook-image-helper';

const HomepageBrands = ({ title, link, type, content }) => {
  return (
    <HomepageSection title={title} link={link} type={type}>
      {content.map(({ nickname, link, recipeImage, recipeName }, index) => (
        <a
          href={link}
          key={nickname}
          className={styles.homepageBrandsItem}
          data-track-id="homepage"
          data-module={title}
          data-position={index + 1}
          data-title={nickname}
          data-type={type}
        >
          <div className={styles.homepageBrandsItemImage}>
            <picture
              className={`${styles.homepageBrandsItemImageSource} ratio-container ratio-container-4-3`}
            >
              <Image
                src={imaginaryUrl(recipeImage, { width: 600 })}
                alt={nickname}
              />
            </picture>
            <div className={styles.homepageBrandsItemImageCaption}>
              <h3
                id={nickname}
                className={styles.homepageBrandsItemImageCaptionRecipeTitle}
              >
                {recipeName}
              </h3>
              <span
                className={styles.homepageBrandsItemImageCaptionOwner}
                aria-describedby={nickname}
              >
                {nickname}
              </span>
            </div>
          </div>
        </a>
      ))}
    </HomepageSection>
  );
};

HomepageBrands.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      nickname: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
      recipeName: PropTypes.string.isRequired,
      recipeImage: PropTypes.string.isRequired
    })
  ).isRequired
};

export default HomepageBrands;
