import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';

const HomepagePopularKeywords = ({ title, type, content }) => {
  return (
    <HomepageSection title={title} className={styles.homepagePopularKeywords}>
      {content.map(({ keyword, link }, index) => (
        <a
          className={styles.homepagePopularKeywordsItem}
          href={link}
          key={keyword}
          data-track-id="homepage"
          data-module={title}
          data-position={index + 1}
          data-type={type}
        >
          <span className={styles.homepagePopularKeywordsItemName}>
            {keyword}
          </span>
        </a>
      ))}
    </HomepageSection>
  );
};

HomepagePopularKeywords.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      keyword: PropTypes.string,
      link: PropTypes.string
    })
  ).isRequired
};

export default HomepagePopularKeywords;
