import styles from './style.module.scss';
import cx from 'classnames';

const HomepageRecipeCardDraft = ({ name, description }) => {
  return (
    <div className={cx(styles.homepageRecipeCardCaption, styles.draftCaption)}>
      <span className={styles.draftTag}>未發布</span>
      <h3
        id={name}
        className={cx(styles.homepageRecipeCardCaptionTitle, styles.draftTitle)}
      >
        {name}
      </h3>
      <span className={styles.draftTwoLineTexts}>{description}</span>
    </div>
  );
};

export default HomepageRecipeCardDraft;
