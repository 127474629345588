import PropTypes from 'prop-types';
import Snackbar from '@polydice/ui/dist/Snackbar';

const SnackbarMigration = ({ children, type, ...otherProps }) => {
  const getCloseable = () => type === 'success' || type === 'info';

  const generateChildren = () => (
    <span dangerouslySetInnerHTML={{ __html: children }}></span>
  );

  return (
    <Snackbar closable={getCloseable()} type={type} {...otherProps}>
      {generateChildren()}
    </Snackbar>
  );
};

SnackbarMigration.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
};

export default SnackbarMigration;
