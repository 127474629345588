import PropTypes from 'prop-types';
import HomepageSection from './HomepageSection';
import HomepagePostCard from './HomepagePostCard';
import HomepageRecipeCard from './HomepageRecipeCard';
import { RecipeSourceTypes, PostSourceTypes } from './types';

const HomepageCuration = ({ title, type, link, content, isVideo }) => {
  return (
    <HomepageSection title={title} link={link} type={type} isMobileScroll>
      {content.map(({ id, ...resetContext }, index) =>
        resetContext.author ? (
          <HomepageRecipeCard
            key={id}
            type={type}
            position={index + 1}
            module={title}
            isVideo={isVideo}
            {...resetContext}
          />
        ) : (
          <HomepagePostCard
            key={id}
            type={type}
            position={index + 1}
            module={title}
            {...resetContext}
          />
        )
      )}
    </HomepageSection>
  );
};

HomepageCuration.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isVideo: PropTypes.bool,
  content: PropTypes.arrayOf(
    PropTypes.oneOfType([RecipeSourceTypes, PostSourceTypes])
  ).isRequired
};

HomepageCuration.defaultTypes = {
  isVideo: false
};

export default HomepageCuration;
