import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import Button from '@polydice/ui/dist/Button';
import VipEntryImage from 'images/vip/homepage-entry.svg';
import VipLogo from 'images/vip/logo.svg';
import market from 'images/icons/channels/market.svg';
import blog from 'images/icons/channels/blog.svg';
import tv from 'images/icons/channels/tv.svg';
import select from 'images/icons/channels/select.svg';
import portal from 'images/icons/channels/vip.svg';

export const ENTRIES = {
  blog: {
    text: '生活誌',
    image: blog
  },
  market: {
    text: '市集',
    image: market
  },
  tv: {
    text: '影音',
    image: tv
  },
  select: {
    text: '試用推薦',
    image: select
  },
  portal: {
    text: '獨享專區',
    image: portal
  }
};

const LANDING_DESCRIPTION = '快速找到零失敗食譜\n輕鬆變化每日菜色';

const HomepageChannelEntries = ({ type, isVip, channelEntries }) => {
  let channels = ['portal', 'blog', 'market', 'tv', 'select'];

  if (!isVip) channels = channels.filter((channel) => channel !== 'portal');

  return (
    <HomepageSection className={styles.homepageChannelEntries}>
      <nav
        className={styles.homepageChannelEntriesNavigation}
        data-with-landing={!isVip}
      >
        {channels.map((channel, index) => (
          <a
            className={styles.homepageChannelEntriesNavigationItem}
            key={channel}
            href={channelEntries[channel]}
            data-track-id="homepage"
            data-module="固定入口"
            data-type={type}
            data-position={index + 1}
          >
            <figure
              className={styles.homepageChannelEntriesNavigationItemImage}
            >
              <img
                className={
                  styles.homepageChannelEntriesNavigationItemImageSource
                }
                src={ENTRIES[channel].image}
                alt={`${ENTRIES[channel].text} icon`}
              />
              <figcaption
                className={
                  styles.homepageChannelEntriesNavigationItemImageCaption
                }
              >
                {ENTRIES[channel].text}
              </figcaption>
            </figure>
          </a>
        ))}
      </nav>
      {!isVip && (
        <a
          className={styles.homepageChannelEntriesVIPLanding}
          data-track-id="vip-entry-homepage-intro"
          href={channelEntries.landing}
        >
          <section className={styles.homepageChannelEntriesVIPLandingContent}>
            <img
              className={styles.homepageChannelEntriesVIPLandingContentLogo}
              src={VipLogo}
              alt="愛料理 VIP"
            />
            <h2
              className={
                styles.homepageChannelEntriesVIPLandingContentDescription
              }
            >
              {LANDING_DESCRIPTION}
            </h2>
            <Button
              className={styles.homepageChannelEntriesVIPLandingContentButton}
              styleType="contained"
              color="primary"
              size="xs"
            >
              查看方案
            </Button>
          </section>
          <img
            className={styles.homepageChannelEntriesVIPLandingImage}
            src={VipEntryImage}
            alt=""
          />
        </a>
      )}
    </HomepageSection>
  );
};

HomepageChannelEntries.propTypes = {
  type: PropTypes.string.isRequired,
  isVip: PropTypes.bool.isRequired,
  channelEntries: PropTypes.shape({
    market: PropTypes.string.isRequired,
    blog: PropTypes.string.isRequired,
    tv: PropTypes.string.isRequired,
    select: PropTypes.string.isRequired,
    portal: PropTypes.string.isRequired,
    landing: PropTypes.string.isRequired
  }).isRequired
};

export default HomepageChannelEntries;
