import PropTypes from 'prop-types';
import styles from './style.module.scss';
import HomepageSection from './HomepageSection';
import { HeroTypes } from './types';
import Image from '@polydice/icook-image-helper';

const HomepageHero = ({ type, major, minor }) => {
  return (
    <HomepageSection className={styles.homepageHero}>
      <a
        href={major.link}
        className={styles.homepageHeroMajor}
        data-track-id="homepage"
        data-module="Hero"
        data-type={type}
        data-position={major.position}
        data-title={major.title}
      >
        <figure className={styles.homepageHeroMajorImage}>
          {/* Use this svg to fool lighthouse 6 LCP */}
          <img
            className={styles.homepageHeroMajorImageSource}
            alt={major.title}
            src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1' height='1' viewBox='0 0 1 1'%3E%3Crect width='100%25' height='100%25' fill='transparent'%3E%3C/rect%3E%3C/svg%3E"
          />
          <Image
            className={styles.homepageHeroMajorImageSource}
            alt={major.title}
            src={major.image}
            srcSet={[600, 1200]}
            sizes="(max-width: 767px) 100vw, (min-width: 768px) 600px"
            loading="eager"
          />
          <figcaption className={styles.homepageHeroMajorImageCaption}>
            <span
              className={styles.homepageHeroMajorImageCaptionSubtitle}
              aria-describedby={major.title}
            >
              {major.subtitle}
            </span>
            <h1
              id={major.title}
              className={styles.homepageHeroMajorImageCaptionTitle}
            >
              {major.title}
            </h1>
          </figcaption>
        </figure>
      </a>
      <section className={styles.homepageHeroMinor}>
        {minor.map(({ link, id, position, title, subtitle, image }) => (
          <a
            href={link}
            className={styles.homepageHeroMinorItem}
            key={id}
            data-track-id="homepage"
            data-module="Hero"
            data-type={type}
            data-position={position}
            data-title={title}
          >
            <figure className={styles.homepageHeroMinorItemImage}>
              <Image
                className={styles.homepageHeroMinorItemImageSource}
                alt={title}
                src={image}
                srcSet={[144, 900]}
                sizes="(max-width: 767px) 144px, (min-width: 768px) 900px"
                loading="eager"
              />
              <figcaption className={styles.homepageHeroMinorItemImageCaption}>
                <span
                  className={styles.homepageHeroMinorItemImageCaptionSubtitle}
                  aria-describedby={title}
                >
                  {subtitle}
                </span>
                <h2
                  id={title}
                  className={styles.homepageHeroMinorItemImageCaptionTitle}
                >
                  {title}
                </h2>
              </figcaption>
            </figure>
          </a>
        ))}
      </section>
    </HomepageSection>
  );
};

HomepageHero.propTypes = {
  type: PropTypes.string.isRequired,
  major: HeroTypes.isRequired,
  minor: PropTypes.arrayOf(HeroTypes).isRequired
};

export default HomepageHero;
