import '../../assets/javascripts/components/tracking';

import '../../assets/javascripts/components/sentry';
import '../../assets/javascripts/components/ad/dfpSnippet';
import '../../assets/javascripts/components/facebook-sdk';
import '../../assets/javascripts/components/header-popular-keywords';
import '../../assets/javascripts/components/header-categories';
import { triggerMessagingAuth } from '../../assets/javascripts/components/firebase';
import { checkIsUserLoggedIn } from '@polydice/icook-devise-unify';
if (checkIsUserLoggedIn()) {
  triggerMessagingAuth();
}
import '@polydice/icook-navbar';
import 'stylesheets/homepage.scss';

import ReactOnRails from 'react-on-rails';
import Homepage from '../../assets/javascripts/reactjs/components/Homepage/Homepage';
import SearchBox from '../../assets/javascripts/reactjs/components/searchBox/searchBox';
import Snackbar from '../../assets/javascripts/reactjs/components/Snackbar';
import { noHydrate } from '../../assets/javascripts/reactjs/utils/hydrate';

ReactOnRails.register({
  BrowserDetector: noHydrate,
  Homepage,
  SearchBox,
  Snackbar
});
