import styles from './style.module.scss';
import cx from 'classnames';

const homepageRecipeCardCaption = ({
  name,
  author,
  twoLineTitle = false,
  isUserPageEdit = false
}) => {
  return (
    <div className={styles.homepageRecipeCardCaption}>
      <h3
        id={name}
        className={cx(styles.homepageRecipeCardCaptionTitle, {
          [styles.twoLineTitle]: twoLineTitle,
          [styles.isUserPageEditCaptionTitle]: isUserPageEdit
        })}
      >
        {name}
      </h3>
      <span
        className={cx(styles.homepageRecipeCardCaptionAuthor, {
          [styles.isUserPageEditCaptionAuthor]: isUserPageEdit
        })}
        aria-describedby={author}
      >
        {author}
      </span>
    </div>
  );
};

export default homepageRecipeCardCaption;
